import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Menu from '../Menu';
import Footer from '../Footer/Footer';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <Container fluid style={{backgroundColor: 'lightgreen'}}>
      <Menu />

      <Row className="my-5 d-flex justify-content-center align-items-center hero-section-1">
        <Col md={8} className="mx-auto text-center">
          <h1 className="mb-4">About Our Company</h1>
          <p className="lead">
            We are a leading construction company with a focus on building sustainable and energy-efficient structures. Our team of experienced professionals is committed to delivering high-quality construction services to our clients.
          </p>
        </Col>
      </Row>

      <Row className="my-5 py-5 d-flex justify-content-center align-items-center text-center separator">
        <Col md={4}>
          <h2>"The Earth is what we all have in common. Let's cherish and protect it."</h2>
        </Col>
      </Row>

      <Row className="my-5 py-5 d-flex justify-content-center align-items-center text-center">
        <Col md={6}>
            <h2>Our Team</h2>
            <p>Our team consists of experienced professionals dedicated to delivering high-quality construction services.</p>

        </Col>
        <Col md={6}>
            <h2>Contact Us</h2>
            <p>Get in touch with us to learn more about our services and how we can help you with your construction needs.</p>
        </Col>
      </Row>
      <Row className="my-5 py-5 d-flex justify-content-center align-items-center text-center">
        <Col md={6}>
          <h2>Get in Touch with Our Team</h2>
          <p>If you have any questions or need further information, don't hesitate to contact our team. We're here to help!</p>
          <Button variant="success" onClick={() => window.location.href = '/contact'}>Contact Our Team</Button>
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}

export default AboutPage;