import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Container } from '@material-ui/core';
import Menu from '../../Menu';
import Footer from '../../Footer/Footer';
import './HomePodcast.css';
import { Modal } from '@material-ui/core';

import { IconButton } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import image1 from '../../../images/studio/home-podcast-simple.png';
import image2 from '../../../images/studio/home-podcast-very-simple.png';
import image3 from '../../../images/studio/home-podcast-desk-studio.png';
import image4 from '../../../images/studio/home-podcast-two-screens.png';
import image5 from '../../../images/studio/home-podcast-two-mic.png';
import image6 from '../../../images/studio/home-podcast-two-screens-2-mics.png';
import image7 from '../../../images/studio/home-podcast-chair-laptop.png';
import image8 from '../../../images/studio/home-podcast-mic-behind.png';
import image9 from '../../../images/studio/home-podcast-simple-mic.png';
import image10 from '../../../images/studio/home-podcast-ipad-mic.png';
import image11 from '../../../images/studio/home-podcast-mobile-holder.png';
import image12 from '../../../images/studio/home-podcast-large-screen.png';
import image13 from '../../../images/studio/home-podcast-three-screens.png';
import image14 from '../../../images/studio/home-podcast-center-table.png';
import image15 from '../../../images/studio/home-podcast-heavy-speakers.png';
import image16 from '../../../images/studio/home-podcast-multiple-screens.png';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    media: {
      height: 400,
    },
    mediaExpanded: {
      height: 800,
    },
    content: {
      marginBottom: theme.spacing(2),
    },
  }));

  
const HomePodcast = () => {
  const classes = useStyles();
  const [selectedDesign] = useState(null);

  const [open, setOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handlePrev = () => {
    setCurrentIndex((oldIndex) => (oldIndex > 0 ? oldIndex - 1 : data.length - 1));
  };
  
  const handleNext = () => {
    setCurrentIndex((oldIndex) => (oldIndex < data.length - 1 ? oldIndex + 1 : 0));
  };

  const data = [
    { title: 'Desk Home Studio', image: image1 },
    { title: 'Decorated Corner', image: image2 },
    { title: 'Single Screen Box', image: image3 },
    { title: 'Multiple Screens', image: image4 },
    { title: 'Small Table', image: image5 },
    { title: 'Medium Table Top', image: image6 },
    { title: 'Natural Lighting', image: image7 },
    { title: 'Professional Podcast', image: image8 },
    { title: 'Two Screens Simple', image: image9 },
    { title: 'Standup Podcast', image: image10 },
    { title: 'Sound Proofing', image: image11 },
    { title: 'Neat Table', image: image12 },
    { title: 'Two People Podcast', image: image13 },
    { title: 'Interview Table', image: image14 },
    { title: 'Heavy Speakers', image: image15 },
    { title: 'Elegant Long Screen', image: image16 },
    // Add more designs as needed
  ];

  

  return (
    <div className={classes.root}>
        <Menu />
        <div className='title-top'></div>
        <Container className="content">
        <Typography  variant="h3" className="title" gutterBottom>
            Interior Design Solution for Home Studios
        </Typography>
        <Typography variant="body1" className="body">
        Welcome to our podcast episode dedicated to transforming your home into the ultimate podcasting oasis! Today, we're diving deep into the world of interior design solutions tailored specifically for podcasters. Whether you're just starting out or looking to upgrade your current setup, we've got you covered with tips, tricks, and innovative design ideas to create a space that not only looks amazing but enhances your podcasting experience.
        </Typography>
      </Container>
      <Grid className='image-grid' container spacing={3}>
        {data.map((design, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card>
            <CardActionArea onClick={() => handleOpen(index)}>
                <CardMedia
                  className={selectedDesign === index ? classes.mediaExpanded : classes.media}
                  image={design.image}
                  title={design.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {design.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
        <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
            <Button variant="contained" style={{ backgroundColor: '#4caf50', color: 'white' }}>
                Contact Us
            </Button>
            </Link>
        </div>
      <Container className="content">
        <Typography  variant="h2" className="title" gutterBottom>
            Crafting Your Perfect Home Podcast Studio
        </Typography>
        <Typography variant="body1">
        <Typography variant="h3" className="title" gutterBottom>
        Designing for Acoustics
        </Typography>
        The cornerstone of any successful podcast studio is impeccable sound quality. We'll discuss how to optimize your space for acoustics, including the strategic placement of sound-absorbing materials, such as acoustic panels and foam tiles, to minimize echoes and background noise. We'll also explore the importance of choosing the right furniture and décor to further enhance soundproofing and audio clarity.
        </Typography>
        <Typography variant="h3" className="title" gutterBottom>
        Ergonomic Workstations
        </Typography>
        <Typography variant="body1" className="body">
        Podcasting often involves long hours spent recording, editing, and producing content. That's why creating an ergonomic workstation is essential for both comfort and productivity. We'll share expert insights on selecting ergonomic furniture, such as adjustable desks and supportive chairs, to prevent fatigue and promote proper posture during those marathon podcasting sessions.
        </Typography>
        <Typography variant="h3" className="title" gutterBottom>
        Lighting Design
        </Typography>
        <Typography variant="body1" className="body">
        Good lighting not only sets the mood but also enhances video quality for podcasts with a visual component. We'll discuss different lighting techniques and fixtures to illuminate your space effectively, whether you're filming video podcasts or simply want to create a welcoming ambiance for your audio recordings. From soft, diffused lighting for a cozy atmosphere to adjustable LED lights for added versatility, we'll help you find the perfect lighting solution for your studio.
        </Typography>
        <Typography variant="h3" className="title" gutterBottom>
        Tech Integration
        </Typography>
        <Typography variant="body1" className="body">
        No podcast studio is complete without the latest tech gadgets and equipment. We'll explore innovative ways to integrate technology seamlessly into your studio design, from cable management solutions to built-in charging stations and smart home automation systems. Whether you're a tech enthusiast or just looking for practical solutions to streamline your workflow, we'll show you how to incorporate cutting-edge technology into your podcasting setup with style.
        </Typography>
        <Typography variant="h3" className="title" gutterBottom>
        Personalization and Branding
        </Typography>
        <Typography variant="body1" className="body">
        Your podcast studio should be a reflection of your unique personality and branding. We'll discuss creative ways to personalize your space, from custom wall art and branding elements to incorporating your podcast's color scheme and logo into the design. Whether you're aiming for a sleek and professional look or a quirky and eclectic vibe, we'll help you infuse your studio with character and charm that aligns with your podcast's identity.
        </Typography>
        <Typography variant="h3" className="title" gutterBottom>
        We ensure your satisfaction
        </Typography>
        <Typography variant="body1" className="body">
        Creating the perfect home podcast studio is a journey that combines creativity, functionality, and technology. With the right interior design solutions, you can transform any space into a professional-grade studio that inspires creativity and elevates your podcasting experience. We hope this episode has provided you with valuable insights and inspiration to embark on your own podcast studio makeover journey. Until next time, happy podcasting!
        </Typography>
      </Container>
        <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
            <Button variant="contained" style={{ backgroundColor: '#4caf50', color: 'white' }}>
                Contact Us
            </Button>
            </Link>
        </div>
        <Footer />
        <Modal
        open={open}
        onClose={handleClose}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // This will make the background darker
        }}
        >
        <div style={{ position: 'relative' }}>
        <IconButton onClick={handlePrev} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
        <ArrowBackIos style={{ color: 'white', fontSize: '2rem' }} />
        </IconButton>
        <img src={data[currentIndex].image} alt="" style={{ maxHeight: '100%', maxWidth: '100%' }} />
        <IconButton onClick={handleNext} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
        <ArrowForwardIos style={{ color: 'white', fontSize: '2rem' }} />
        </IconButton>
        </div>
        </Modal>
    </div>
  );
}

export default HomePodcast;