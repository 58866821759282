import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Menu from '../Menu'; // Import Menu component
import Footer from '../Footer/Footer'; // Import Footer component

const PrivacyPolicy = () => {
  return (
    <>
      <Menu /> {/* Include Menu component */}
      <Container>
        <Row>
          <Col>
            <h1>Privacy Policy</h1>
            <p>
              Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you across our website.
            </p>
            <h2>Information we collect</h2>
            <p>
              We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent.
            </p>
            <h2>How we use your information</h2>
            <p>
              We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
              <ul>
                <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                <li>To improve our website in order to better serve you.</li>
                <li>To allow us to better service you in responding to your customer service requests.</li>
              </ul>
            </p>
            <h2>Privacy Policy changes</h2>
            <p>
              Although most changes are likely to be minor, we may change our Privacy Policy from time to time, and in our sole discretion. We encourage visitors to frequently check this page for any changes to our Privacy Policy.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Include Footer component */}
    </>
  );
};

export default PrivacyPolicy;