import React, { useState }from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Menu from '../Menu';
import Footer from '../Footer/Footer';
import './HomePage.css';
import Card from 'react-bootstrap/Card';

import Select from 'react-select';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

import image1 from '../../images/garden-inside.jpg';
import image2 from '../../images/home-image.jpg';
import image3 from '../../images/kitchen.jpg';
import image4 from '../../images/livingroom-kitchen-view.jpg';
import image5 from '../../images/stairs.jpg';
import image6 from '../../images/projects/1.jpg';
import image7 from '../../images/projects/2.jpg';
import image8 from '../../images/projects/3.jpg';
import image9 from '../../images/projects/4.jpg';
import image10 from '../../images/projects/5.jpg';
import image11 from '../../images/projects/6.jpg';
import image12 from '../../images/projects/7.jpg';
import image13 from '../../images/projects/8.jpg';
import image14 from '../../images/projects/9.jpg';
import image15 from '../../images/projects/10.jpg';
import image16 from '../../images/blueprint.jpg';

const images = [
  { src: image1, width: 4, height: 3, tags: ['tag1', 'tag2'] },
  { src: image2, width: 1, height: 1, tags: ['tag2'] },
  { src: image3, width: 1, height: 1, tags: ['tag3'] },
  { src: image4, width: 1, height: 1, tags: ['tag4'] },
  { src: image5, width: 1, height: 1, tags: ['tag5'] },
  { src: image6, width: 1, height: 1, tags: ['tag5'] },
  { src: image7, width: 1, height: 1, tags: ['tag5'] },
  { src: image8, width: 1, height: 1, tags: ['tag6'] },
  { src: image9, width: 1, height: 1, tags: ['tag6'] },
  { src: image10, width: 1, height: 1, tags: ['tag6'] },
  { src: image11, width: 1, height: 1, tags: ['tag7'] },
  { src: image12, width: 1, height: 1, tags: ['tag7'] },
  { src: image13, width: 1, height: 1, tags: ['tag8'] },
  { src: image14, width: 1, height: 1, tags: ['tag9'] },
  { src: image15, width: 1, height: 1, tags: ['tag9'] },
  { src: image16, width: 1, height: 1, tags: ['tag10'] },
  // more images...
];

const tagOptions = [
  { value: 'tag1', label: 'Garden' },
  { value: 'tag2', label: 'Interior' },
  { value: 'tag3', label: 'Kitchen' },
  { value: 'tag4', label: 'Living Room' },
  { value: 'tag5', label: 'Stairs' },
  { value: 'tag6', label: 'Architectural' },
  { value: 'tag7', label: 'Outdoor' },
  { value: 'tag8', label: 'Bedroom' },
  { value: 'tag9', label: 'Exterior View' },
  { value: 'tag10', label: 'Blueprints' },
  // more tags...
];

const HomePage = () => {

  const [selectedTags, setSelectedTags] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const filteredImages = images.filter(image =>
    selectedTags.length === 0 || image.tags.some(tag => selectedTags.includes(tag))
  );

  return (
    <Container fluid style={{backgroundColor: 'lightgreen'}}>
      <Menu />

      <Row className="my-5 py-5 d-flex justify-content-center align-items-center hero-section-home">
        <Col md={8} className="mx-auto text-center hero-content">
          <h1 className="mb-4">Welcome to Eco Tropic Builders</h1>
          <p className="lead">
            Eco Tropic Builders Private Limited is a new age construction company with a commitment to sustainability and excellence. We have been building eco-friendly and energy-efficient structures for a couple of years. Our team of experienced professionals is dedicated to delivering high-quality construction services to our clients.
          </p>
          <Row className="justify-content-center mt-4">
            <Col xs={12} sm={5} className="btn-col">
              <Button variant="success" href="https://calendar.app.google/iq8SwaUquK8nuJdw9" target="_blank" className="mb-2 mb-sm-0 w-100">
                Schedule an Appointment
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center text-center">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Eco-Friendly Materials</Card.Title>
              <Card.Text>We use sustainable and recycled materials to reduce the environmental impact of our buildings.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Energy Efficiency</Card.Title>
              <Card.Text>Our homes are designed to be energy efficient, reducing energy consumption and saving you money.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Green Spaces</Card.Title>
              <Card.Text>We incorporate green spaces into our designs to improve air quality and provide natural beauty.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center text-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Water Conservation</Card.Title>
              <Card.Text>We implement water-saving features in our homes to conserve this precious resource.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Healthy Living</Card.Title>
              <Card.Text>Our eco-friendly homes promote a healthier lifestyle with better indoor air quality and natural lighting.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className="portfolio-section">
            <h2>Our Portfolio</h2>
          </div>
        </Col>
      </Row>
      <Select
        isMulti
        name="tags"
        options={tagOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={selectedOptions => setSelectedTags(selectedOptions.map(option => option.value))}
      />
      <Gallery photos={filteredImages} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={filteredImages.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Footer />
    </Container>
  );
}

export default HomePage;