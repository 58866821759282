import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Menu from './Menu';
import Footer from './Footer/Footer';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import './contact.css';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pincode, setPincode] = useState('');
  const [address, setAddress] = useState('');
  const [requirement, setRequirement] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);


  useEffect(() => {
    if (name && email && phone && pincode && address && requirement) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [name, email, phone, pincode, address, requirement]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const uuid = uuidv4();
    Cookies.set('uuid', uuid);

    const response = await fetch('https://mongo.exinary.website/submit-eco-leads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        pincode,
        address,
        requirement,
        uuid,
        source: 'ContactPage',
      }),
    });

    if (response.ok) {
      console.log('Form submitted successfully');
      setShowAdditionalFields(true);
    } else {
      console.log('Form submission failed');
    }
  };

  return (
    <div style={{backgroundColor: '#90ee90', minHeight: '100vh'}} className='hero-section-common'>
      <Menu />

      <Container className="d-flex align-items-center justify-content-center custom-form" style={{height: '100vh'}}>
        <Row>
          <Col md={4} className="contact-col">
          <div className="bottom-align">
            <h2>Contact Us</h2>
          </div>
          </Col>
          <Col md={8}>
          {!showAdditionalFields ? (
            <Form onSubmit={handleSubmit} className='form-of-contact'>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="tel" placeholder="Enter your phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control type="text" placeholder="Enter your pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control as="textarea" placeholder="Enter your address" value={address} onChange={(e) => setAddress(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Requirement</Form.Label>
                <Form.Control as="textarea" placeholder="Enter your requirement" value={requirement} onChange={(e) => setRequirement(e.target.value)} />
              </Form.Group>

              <Form.Text className={formValid ? "text-success" : "text-danger"}>
                {formValid ? "All fields are valid" : "Please fill out all fields to submit the form"}
              </Form.Text>
              
              <Button variant="primary" type="submit" disabled={!formValid || submitting} className="mt-3 w-100" style={{ display: submitting ? 'none' : 'block' }}>
                Submit
              </Button>

              <Form.Text className="text-info" style={{ display: submitting ? 'block' : 'none' }}>
                Submitting the details you provided, please wait...
              </Form.Text>

            </Form>
            ) : (
              <div className="thank-you-message">
                <h2>Thank you for your submission!</h2>
                <p>We will get back to you as soon as possible.</p>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default ContactPage;