import React from 'react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import Menu from '../Menu';
import Footer from '../Footer/Footer';
import './InteriorDesign1.css';
import image1 from '../../images/garden-inside.jpg';
import image2 from '../../images/home-image.jpg';
import image3 from '../../images/kitchen.jpg'
import image4 from '../../images/livingroom-kitchen-view.jpg'
import image5 from '../../images/stairs.jpg'

const InteriorDesign1 = () => {
  const content = [
    { title: 'Space Planning', text: 'Analyzing the layout of a space and determining the most efficient and functional arrangement of furniture, fixtures, and equipment to optimize flow and usability.' },
    { title: 'Concept Development', text: 'Creating design concepts and themes tailored to the your preferences, lifestyle, and budget, incorporating elements such as color schemes, materials, and architectural features.' },
    { title: 'Furniture Selection and Procurement', text: 'Assisting you in selecting and sourcing furniture, accessories, and decor items that complement the design concept while meeting functional requirements and aesthetic preferences.' },
    { title: 'Custom Cabinetry and Built-ins', text: 'Designing and fabricating custom cabinetry, shelving units, and built-in storage solutions to maximize space utilization and enhance organization while maintaining visual harmony with the overall design.' },
    { title: 'Material and Finish Selection', text: 'Recommending appropriate materials, finishes, and textures for flooring, walls, ceilings, and other surfaces to achieve the desired aesthetic, durability, and maintenance requirements.' },
    { title: 'Lighting Design', text: 'Designing lighting schemes that enhance the ambiance, functionality, and visual appeal of interior spaces, including ambient, task, and accent lighting solutions tailored to specific needs and preferences.' },
    { title: 'Window Treatments', text: 'Providing recommendations for window treatments such as curtains, blinds, shades, and drapes to control natural light, ensure privacy, and enhance the overall design aesthetic.' },
    { title: 'Color Consultation', text: 'Offering expert advice on color selection and coordination to create cohesive and harmonious color schemes that reflect your style preferences and contribute to the desired atmosphere.' },
    { title: 'Accessorizing and Styling', text: 'Curating and arranging accessories, artwork, and decorative elements to add personality, interest, and visual impact to the space while complementing the overall design theme.' },
    { title: 'Project Management', text: 'Overseeing the implementation of the design plan, coordinating with contractors, vendors, and suppliers, and ensuring that the project is completed on time, within budget, and according to your specifications.' },
    { title: 'Home Staging', text: 'Assisting homeowners, real estate agents, and property developers in preparing homes for sale by enhancing their visual appeal and creating a welcoming atmosphere that resonates with potential buyers.' },
    { title: 'Renovation and Remodeling', text: 'Providing design expertise and project management services for renovation and remodeling projects, including kitchen and bathroom renovations, whole-home makeovers, and structural modifications to improve functionality and aesthetic appeal.' },
  ];

  const professionalstudioservices = [
    { service: `Acoustic Design: We can collaborate with acoustic engineers to optimize the studio's sound quality by selecting appropriate materials, designing room layouts, and implementing soundproofing solutions.` },
    { service: `Space Planning: We can assist in optimizing the layout of the studio space to accommodate various recording, mixing, and mastering equipment while ensuring efficient workflow and ergonomic design.` },
    { service: `Aesthetic Design: We can enhance the visual appeal of the studio by selecting finishes, furnishings, and decor elements that create a conducive and inspiring environment for creativity and productivity.` },
    { service: `Custom Cabinetry and Built-ins: Designing and installing custom storage solutions and built-in furniture to maximize space utilization and organization, catering to the specific needs of the studio environment.` },
    { service: `Lighting Design: Designing lighting schemes that enhance visibility, ambiance, and functionality within the studio space, including task lighting for recording and mixing areas, and ambient lighting for overall atmosphere.` },
    { service: `Technology Integration: Collaborating with audiovisual specialists to seamlessly integrate technology and equipment into the studio space, ensuring optimal performance and user experience.` },
  ];

  const homestudioservices = [
    { service: `Acoustic Design: We can advise on acoustics and soundproofing to create an optimal recording environment within the home studio, ensuring clear sound quality and minimal interference from external noise.` },
    { service: `Space Planning: We can help optimize the layout of the home studio to accommodate recording equipment, instruments, and workstations while maximizing space efficiency and workflow.` },
    { service: `Aesthetic Design: We can enhance the visual appeal of the home studio with finishes, lighting, and decor that inspire creativity and reflect the user's personal style, creating a conducive environment for music production and content creation.` },
    { service: `Custom Cabinetry and Built-ins: Designing and installing custom storage solutions and built-in furniture to organize equipment, cables, and accessories, maximizing functionality and minimizing clutter within the home studio space.` },
    { service: `Lighting Design: We can design lighting schemes that provide adequate illumination for recording and filming purposes, while also creating a comfortable and visually appealing atmosphere within the home studio.` },
    { service: `Technology Integration: Collaborating with audiovisual specialists, we can integrate recording software, hardware, and multimedia equipment seamlessly into the home studio setup, ensuring ease of use and optimal performance for music production and content creation.` },
  ];

  const hometheatreservices = [
    { service: `Acoustic Design: We can advise on acoustics and soundproofing to optimize the audio experience within the home theatre, ensuring clear and immersive sound quality.` },
    { service: `Space Planning: We can help optimize the layout of the home theatre to accommodate audiovisual equipment, seating arrangements, and lighting fixtures while maximizing comfort and viewing angles.` },
    { service: `Aesthetic Design: We can enhance the visual appeal of the home theatre with finishes, lighting, and decor that create a cinematic atmosphere and complement the overall design theme of the space.` },
    { service: `Custom Cabinetry and Built-ins: Designing and installing custom storage solutions and built-in furniture to organize audiovisual equipment, media collections, and accessories, maximizing functionality and minimizing clutter within the home theatre space.` },
    { service: `Lighting Design: We can design lighting schemes that enhance the viewing experience, providing ambient lighting for comfortable viewing and accent lighting for dramatic effect, while also incorporating dimming controls for versatility.` },
    { service: `Technology Integration: Collaborating with audiovisual specialists, we can integrate audio systems, video projectors, screens, and smart home automation technology seamlessly into the home theatre setup, ensuring ease of use and optimal performance for cinematic entertainment experiences.` },
  ];

  return (
    <Container fluid className="p-0">
      <Menu />
      <Container fluid className="hero-section">
      <div>
        <h1>Interior Design Services</h1>
        <p>Discover our wide range of interior design services tailored to your needs.</p>
      </div>
      </Container>
      <Row className="my-5 py-5 d-flex justify-content-center align-items-center text-center">
        <Col md={6} className="mb-4 pb-4">
          <h2>Interior Design 3D Visualization</h2>
          <p className="text-left">We offer 3D of interiors along with Building Plan, Elevation and Architectural Solutions. Our end to end solutions will help you relax and be at peace watching the construction progress at pace. </p>
          <Button variant="success" onClick={() => window.location.href = '/interior-design/3d-apartment'}>Get a 3D Design</Button>
        </Col>
        <Col md={6} className="mb-4 pb-4">
          <h2>Cost Estimation</h2>
          <p className="text-left">Our cost estimation will help you plan the various aspects of work involved in construction and get to know the cost of materials, labour contract, interior designing cost and time span. Get a Quote NOW! </p>
          <Button variant="success" onClick={() => window.location.href = '/contact'}>Planning Quote</Button>
        </Col>
      </Row>
      <Container fluid className="hero-section-2">
        <div>
          <h1>Sustainable materials used in Interior Designing</h1>
          <p className="text-left">Sustainable materials in construction offer numerous benefits. From reducing environmental impact to promoting healthier indoor air quality, they play a pivotal role in creating more eco-friendly and resilient structures. Examples include recycled steel, bamboo, reclaimed wood, and low-emission insulation. These materials conserve natural resources, minimize waste, and lower energy consumption during production and use. Additionally, they often have superior durability and require less maintenance, contributing to long-term cost savings. By incorporating sustainable materials, construction projects can mitigate carbon emissions, enhance building performance, and foster healthier, more sustainable communities.</p>
        </div>
      </Container>
      <Row>
        <Col>
        <div className="services-title">
          <h2>The Services We Provide</h2>
        </div>
        </Col>
      </Row>
      <div className='service-boxes'>
        {content.map((item, index) => (
          <div key={index}>
            <h1>{item.title}</h1>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
      <Row className="my-5 py-5 d-flex justify-content-center align-items-center text-center">
        <Col md={4} className="mb-3 pb-3">
          <h2>Professinal Studio</h2>
          <p>
            <ul className="custom-list">
              {professionalstudioservices.map((item, index) => (
                <li key={index}>{item.service}</li>
              ))}
            </ul>
          </p>
          <p>
            <Button variant="success" onClick={() => window.location.href = '/contact'}>Build a Recording Studio</Button>
          </p>
        </Col>
        <Col md={4} className="mb-3 pb-3">
          <h2>Home Theatre</h2>
          <p>
            <ul className="custom-list">
              {hometheatreservices.map((item, index) => (
                <li key={index}>{item.service}</li>
              ))}
            </ul>
          </p>
          <p>
          <Button variant="success" onClick={() => window.location.href = '/contact'}>Provision a Home Theatre</Button>
          </p>
        </Col>
        <Col md={4} className="mb-3 pb-3">
          <h2>Home Podcast Studio</h2>
          <p><ul className="custom-list">
              {homestudioservices.map((item, index) => (
                <li key={index}>{item.service}</li>
              ))}
            </ul>
          </p>
          <p>
            <Button variant="success" onClick={() => window.location.href = '/interior-design/home-podcast-studio'}>Design a Home Studio</Button>
          </p>
        </Col>
      </Row>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image4}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image5}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <Footer className="footer-green" />
    </Container>
  );
};

export default InteriorDesign1;