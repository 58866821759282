import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
  return (
    <Row className="footer">
      <Col>
        <p>&copy; {new Date().getFullYear()} Eco Tropic Builders. All rights reserved.</p>
      </Col>
    </Row>
  );
}

export default Footer;