import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../eco.svg'; // Path to your SVG file
import '../css/Menu.css'; // Import the CSS file
import { NavDropdown } from 'react-bootstrap';

const Menu = () => {
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" className="mx-0 px-3" style={{backgroundColor: '#76b478', width: '100%'}}>
      <Navbar.Brand href="/">
        <img
          src={Logo}
          width="60" // Double the size
          height="60" // Double the size
          className="d-inline-block align-top logo" // Add the 'logo' class
          alt="Eco Tropic Builders logo"
          style={{ paddingRight: '10px' }} // Add 10px padding to the right
        />
        {/* Eco Tropic Builders */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav className="ms-auto align-right">
          <Nav.Link as={Link} to="/" className="menu-button">Home</Nav.Link>
          <Nav.Link as={Link} to="/about" className="menu-button">About</Nav.Link>
          <Nav.Link as={Link} to="/appointment" className="menu-button">Appointment</Nav.Link>
          <NavDropdown title="Interior Design" id="basic-nav-dropdown" className="menu-button">
            <NavDropdown.Item as={Link} to="/interior-design/">3D Designs</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/interior-design/home-podcast-studio">Studio Designs</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/interior-design/home-podcast-studio">Podcast Studio</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/interior-design/">Home Interior Design</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/contact" className="menu-button">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Menu;