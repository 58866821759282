import React from 'react';
import Menu from './Menu';
import Footer from './Footer/Footer';
import '../css/Appointment.css'; 
const Appointment = () => {
  return (
    <div className="appointment-page">
      <Menu />
      <div className="hero">
        <div className="appointment-section">
          <h1>Appointment Scheduler</h1>
          <p className='text-center'>Please schedule your appointment using the Google Meet link below:</p>
          <a href="https://calendar.app.google/iq8SwaUquK8nuJdw9" target="_blank" rel="noopener noreferrer">Schedule Appointment</a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Appointment;